import BaseModel from '@/models/base';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import { RENT_DURATION_MONTHS_MAX, RENT_DURATION_MONTHS_MIN } from '@/models/flatbond_draft';

export const FEATURE_FLAGS = {
  DEPOSIT_REPLACEMENT_SCHEDULE: 'deposit_replacement_schedule',
  SHOW_OLD_DEPOSIT_PAGE: 'show_old_deposit_page',
  AGENT_TRAINING_FLOW: 'agent_training_flow',
  OPS_LANDLORD_OFFER_V2: 'ops_landlord_offer_v2',
  FLATFAIR_2_0: 'flatfair_2.0',
  UNLOCK_DEPOSIT: 'unlock_deposit',
  MORE_THAN_FOUR_TENANTS: 'more_than_four_tenants',
  MORE_THAN_FOUR_GUARANTORS: 'more_than_four_guarantors',
  BTR_SIGNUP: 'btr_signup',
  LANDLORD_SIGNATURE_IMPROVEMENTS: 'landlord_signature_improvements',
  PARTNER_DASHBOARD: 'partner_dashboard',
  ORGANISATION_SELLER: 'organisation_seller',
  FREE_BOOST_FLOW: 'free_boost_flow',
  NEW_DETAILS_PAGE: 'new_details_page',
  PUBLIC_PAYMENT_LINK: 'public_payment_link',
  OPS_EOT_DEBT_COLLECTION_IMPROVEMENTS: 'ops_eot_debt_collection_improvements',
  OPS_GUARANTOR_CREATOR: 'ops_guarantor_creator',
  OPS_LLO_LANDLORD_DETAILS: 'ops_llo_landlord_details',
  OPS_LLO_IMPROVED_INITIAL_VALUES: 'ops_llo_improved_initial_values',
  REFERENCING_UPSELL_FLOW: 'referencing_upsell_flow',
  REFERENCING_NEW_TENANT_LIST: 'referencing_new_tenant_list',
  AGENT_TASK_LIST: 'agent_task_list',
  NEW_SIGNUP_PAGE: 'new_signup_page',
  BOOST: 'boost',
  AGENT_TRAINING_INTERACTIVE_STEPS: 'agent_training_interactive_steps',
  FLATFAIR_COMMUNITY: 'flatfair_community',
  AGENT_SCRATCH_CARDS: 'agent_scratch_cards',
  NOTIFICATIONS: 'notifications',
  TPG_NO_DEPOSIT_FORCE: 'tpg_no_deposit_force',
  TPG_TRADITIONAL_DEPOSIT_FORCE: 'tpg_traditional_deposit_force',
  RENT_ARREARS: 'rent_arrears',
  PROSPECTIVE_LANDLORD: 'prospective_landlord',
  PRE_VERIFY_AUTOMATICALLY: 'pre_verify_automatically',
  PRE_VERIFY_ON_WEEKEND: 'pre_verify_on_weekend'
};

export const USER_FLAGS = {
  MANAGER: 'manager'
};

class ConfigOrganizationGroup extends BaseModel {
  defaults() {
    return {};
  }
}

class ConfigOrganizationBrand extends BaseModel {
  defaults() {
    return {};
  }
}

class ConfigOrganizationUnit extends BaseModel {
  constructor(data) {
    super(data);
    this.brand = this.brand && new ConfigOrganizationBrand(this.brand);
  }

  defaults() {
    return {};
  }
}

class ConfigOrganizationUser extends BaseModel {
  constructor(data) {
    super(data);
    this.homeUnit = this.homeUnit && new ConfigOrganizationUnit(this.homeUnit);
    this.group = this.group && new ConfigOrganizationGroup(this.group);
  }

  defaults() {
    return {
      groupName: ''
    };
  }
}

export class ConfigUserStateModel extends BaseModel {
  defaults() {
    return {
      id: null,
      hasSeenIncentiveDashboardIntro: false,
      hasSeenPlanPageAnnouncement: false,
      hasSeenPlanDetailsPageAnnouncement: false,
      hasSeenCaseIntroductionModal: false,
      hasSeenReferencingConversionAnnouncement: false,
      hasSeenUpsellPageAnnouncement: false,
      hasSignedUpToTheCommunity: false,
      hasDismissedAgentCashRewardsWalkThrough: true,
      hasSeenRewardsDashboardIntro: false,
      hasDismissedAgentRewardsWalkThrough: true,
      hasRegisteredInterestInResearch: false,
      hasToSeeNewVerticalNavbarWalkThrough: false,
      hasSeenFlatfairDepositsModal: false,
      hasSeenFlatfairDepositsWalkthrough: false,
      hasSeenNewRentArrearsModal: false,
      hasSeenNewEarlyTerminationAnnouncement: false
    };
  }
}

export class ConfigUserModel extends BaseModel {
  constructor(data) {
    super(data);
    this.currentOrganizationUser =
      this.currentOrganizationUser && new ConfigOrganizationUser(this.currentOrganizationUser);
    this.organizationUsers =
      this.organizationUsers &&
      this.organizationUsers.map(user => new ConfigOrganizationUser(user));
    this.tags = this.tags && new Set(this.tags);
    this.state = this.state && new ConfigUserStateModel(this.state);
  }

  get name() {
    return this.fullName;
  }

  get isOnboarding() {
    return get(this, 'currentOrganizationUser.group.isOnboarding', false);
  }

  get isOrganizationLandlord() {
    return get(this, 'currentOrganizationUser.group.isLandlord', false);
  }

  get organizationBoostRentPercentage() {
    return get(this, 'currentOrganizationUser.group.boostRentPercentage', undefined);
  }

  get organizationId() {
    return get(this, 'currentOrganizationUser.group.id', undefined);
  }

  get isOrganizationAdmin() {
    return get(this, 'currentOrganizationUser.isAdmin', false);
  }

  get migrationStatus() {
    return get(this, 'currentOrganizationUser.group.migrationFrom10Status', null);
  }

  get isABTRAgent() {
    return get(this, 'currentOrganizationUser.group.isLandlord', false);
  }

  get hasTraditionalDepositsHandledOffPlatform() {
    return !!get(
      this,
      'currentOrganizationUser.homeUnit.traditionalDepositsHandledOffPlatform',
      false
    );
  }

  defaults() {
    return {
      id: null,
      fullName: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      tags: new Set(),
      state: new ConfigUserStateModel(),
      isATenantOnAPlan: false,
      isATenantOnAReferencingApplication: false,
      isAGuarantorOnAPlan: false,
      isAGuarantorOnAReferencingApplication: false,
      pendingClose: false,
      integrationUserWithoutPassword: false
    };
  }
}

const DEFAULT_LOCALE = 'en-GB';

export class ConfigModel extends BaseModel {
  constructor(data) {
    super(data);
    this.user = this.user && new ConfigUserModel(this.user);
    this.features = this.features && new Set(this.features);
  }

  get isUserLoggedIn() {
    return this.user && this.user.id != null;
  }

  get csrfToken() {
    return Cookies.get('csrftoken') || '';
  }

  get migrationStatus() {
    return get(this, 'user.currentOrganizationUser.group.migrationFrom10Status', null);
  }

  get canOfferDeposits() {
    return (
      get(this, 'user.currentOrganizationUser.group.canOfferDeposits', false) &&
      this.featureEnabled(FEATURE_FLAGS.SHOW_OLD_DEPOSIT_PAGE)
    );
  }

  get isOrganizationLandlord() {
    return get(this, 'user.currentOrganizationUser.group.isLandlord', false);
  }

  get isOrganizationAdmin() {
    return get(this, 'user.currentOrganizationUser.isAdmin', false);
  }

  get isOnboarding() {
    return get(this, 'user.currentOrganizationUser.group.isOnboarding', false);
  }

  get organizationBoostRentPercentage() {
    return get(this, 'user.currentOrganizationUser.group.boostRentPercentage', 1);
  }

  get organizationMinTenancyDurationInMonths() {
    return get(
      this,
      'user.currentOrganizationUser.group.minTenancyDurationInMonths',
      RENT_DURATION_MONTHS_MIN
    );
  }

  get organizationMaxTenancyDurationInMonths() {
    return get(
      this,
      'user.currentOrganizationUser.group.maxTenancyDurationInMonths',
      RENT_DURATION_MONTHS_MAX
    );
  }

  get acceptsExternalTenancyIdPlanCreation() {
    return get(
      this,
      'user.currentOrganizationUser.group.acceptsExternalTenancyIdPlanCreation',
      false
    );
  }

  get organizationCanCreateTraditionalDeposit() {
    return get(this, 'user.currentOrganizationUser.group.canCreateTraditionalDeposit', false);
  }

  get organizationSellersIncentiveUntil() {
    return get(this, 'user.currentOrganizationUser.group.sellersIncentiveUntil', null);
  }

  get organizationId() {
    return get(this, 'user.currentOrganizationUser.group.id', undefined);
  }

  get brandName() {
    return get(this, 'user.currentOrganizationUser.homeUnit.brand.name', undefined);
  }

  get organizationReferencingEnabled() {
    return get(this, 'user.currentOrganizationUser.group.canOfferReferencing', false);
  }

  get sentryUserData() {
    return {
      email: get(this, 'user.email', 'Unknown'),
      organisation: get(this, 'user.currentOrganizationUser.group.id', 'Unknown'),
      name: get(this, 'user.fullName', 'Unknown')
    };
  }

  get hotjarUserData() {
    return {
      'Group ID': get(this, 'user.currentOrganizationUser.group.id', undefined),
      'Group name': get(this, 'user.currentOrganizationUser.group.name', undefined),
      'Home unit ID': get(this, 'user.currentOrganizationUser.homeUnit.id', undefined),
      'Home unit name': get(this, 'user.currentOrganizationUser.homeUnit.name', undefined),
      'Home unit brand name': get(
        this,
        'user.currentOrganizationUser.homeUnit.brand.name',
        undefined
      ),
      'Viewing as': this.viewingAs
    };
  }

  get hasFlatfairDepositsEnabled() {
    return get(this, 'user.currentOrganizationUser.homeUnit.tenancyProtectionGatewayEnabled', null);
  }

  get isProduction() {
    return this.env === 'production';
  }

  get googleMapsApiKey() {
    return 'AIzaSyCplKe7HA9FNKhvzvtb1rbj6XL_7I5b4UA';
  }

  get referencingUpSellHeaderExperiment() {
    return get(this, 'experiments.ReferencingUpSellHeader.header_version', null);
  }

  get referencingUpSellBenefitsCopyExperiment() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const abTestOverride = urlParams.get('abTestOverride');
      if (abTestOverride && this.isProduction === false) {
        return abTestOverride;
      }
      return get(this, 'experiments.ReferencingUpsellBenefitsCopy.header_version', null);
    } catch {
      return get(this, 'experiments.ReferencingUpsellBenefitsCopy.header_version', null);
    }
  }

  get referencingUpSellHighRentHeaderExperiment() {
    return get(this, 'experiments.ReferencingUpSellHighRentHeader.header_version', null);
  }

  get canViewAs() {
    return get(this, 'user.canViewAs', []);
  }

  get hasAtLeastOneActivePaidBoostSubscription() {
    return get(this, 'user.hasAtLeastOneActivePaidBoostSubscription', false);
  }

  defaults() {
    return {
      locale: DEFAULT_LOCALE,
      features: new Set()
    };
  }

  featureEnabled(feature) {
    return this.features.has(feature);
  }
}
