import { OrganisationUnitApiBase } from './base';

export class OrganisationUnitApi extends OrganisationUnitApiBase {
  listBranches(filterInactive = false) {
    let queryParams = '';
    if (filterInactive) {
      queryParams = 'inactive_since__isnull=true';
    }
    return super.get(`?${queryParams}`, 'Failed to list branches');
  }

  getHomeUnit() {
    return super.get('home-unit', 'Failed to list home unit');
  }

  getPartnerDashboardToken(branchIds) {
    return super.get(`get-partner-dashboard-holistic-token/?branch_ids=${branchIds}`);
  }

  get(unitId) {
    return super.get(`${unitId}`);
  }

  revenue() {
    return super.get(`/revenue`);
  }

  flatbondsSold() {
    return super.get(`/flatbonds-sold`);
  }

  createBranch(unitModel) {
    return super.post('', unitModel.toFlatpieModel(), 'Failed to create a branch');
  }
}
